import React from "react";

import "../styles/Policy.scss";

const Policy = ({ isVisible, onPolicyClick }) => {
  return (
    <section className={`Policy ${isVisible ? "visible" : "hidden"}`}>
      {isVisible && <div className="policy-carpet" onClick={onPolicyClick} />}
      <section className="policy-card">
        <main className="policy-info">
          <span className="policy-sub-heading">Privacy policy</span>
          <span className="policy-heading">Why do we process your data?</span>
          <p className="policy-text">
            Generally, we use your data for marketing purposes. Please unfold
            this section, and you’ll understand how we do this.{" "}
            <a
              href="https://eleks.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              More info
            </a>{" "}
            <br />
            <br /> Please keep in mind that when we are processing your data
            based on your consent, you can always withdraw that consent by
            leaving us a clear note at privacy(at)eleks.com at any time or by
            clicking the Unsubscribe button under any email received from us.
            <br />
            <br /> Also, you may always object to our processing activities
            based on the legitimate interest and have your data completely
            erased from all our systems. Just write us about your intent at
            privacy(at)eleks.com. <br />
            <br />
            In both cases, we’ll stop the processing of your data as soon as we
            find your note in our Inbox, which shall not take more than a
            business day. <br />
            <br />
            Please also note that if you withdraw your consent for direct
            marketing by clicking Unsubscribe button, we will not delete the
            rest of your data automatically. But you may request us to do so by
            sending us an email to the address above.
          </p>
        </main>
        <footer className="policy-footer">
          <button className="policy-close" onClick={onPolicyClick}>
            Close
          </button>
        </footer>
      </section>
    </section>
  );
};

export default Policy;
