import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sizes, regions, vendors, mapColors, maps } from "../data/form";

import { ReactComponent as ChevronLeftIcon } from "../icons/chevron-left.svg";

import "../styles/FormPage.scss";

const FormPage = () => {
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [worldPart, setWorldPart] = useState("USA");
  const [region, setRegion] = useState("");
  const [vendor, setVendor] = useState("");

  const navigate = useNavigate();

  const isFormFilled = name && size && worldPart && region && vendor;

  const onSizeClick = (ev) => {
    ev.preventDefault();
    setSize(ev.target.value);
  };

  const onMapClick = (clickedRegion) => {
    if (!region) {
      colorRegion(clickedRegion, mapColors.active);
      setRegion(clickedRegion);
    } else if (region === clickedRegion) {
      colorRegion(clickedRegion, mapColors.disabled);
      setRegion("");
    } else {
      colorRegion(clickedRegion, mapColors.active);
      colorRegion(region, mapColors.disabled);
      setRegion(clickedRegion);
    }
  };

  const colorRegion = (region, color) => {
    const regionElement = document.getElementById(region);
    for (const child of regionElement.children) {
      child.setAttribute("fill", color);
    }
  };

  const submitForm = (ev) => {
    ev.preventDefault();

    const data = { name, size, worldPart, region, vendor };
    sessionStorage.setItem("userData", JSON.stringify(data));

    navigate("/generating", { replace: true });
  };

  return (
    <form className="FormPage">
      <main className="main">
        <Link to="/welcome">
          <ChevronLeftIcon />
        </Link>
        <span className="sub-heading">Information form</span>
        <span className="heading">Help us to propose best option</span>
        <section className="input-container">
          <span className="label">1. Company</span>
          <input
            className="input"
            type="text"
            placeholder="Amazon"
            onChange={(ev) => setName(ev.target.value)}
          />
        </section>
        <section className="input-container">
          <span className="label">2. Company size</span>
          {sizes.map((item) => (
            <button
              key={item}
              value={item}
              className={`choice-button ${size === item ? "active" : ""}`}
              onClick={onSizeClick}
            >
              {item}
            </button>
          ))}
        </section>
        <section className="input-container">
          <span className="label region">
            3. Where are your data centers located?
            <br />
            <span className={`region-label ${region ? "visible" : ""}`}>
              {region || "None"}
            </span>
          </span>
          <div className="world-parts">
            <div className="world-part">
              <input
                type="radio"
                id="world-part-USA"
                name="worldPart"
                value="USA"
                className="input-world-part"
                onChange={(e) => {
                  setWorldPart(e.target.value);
                  setRegion("");
                }}
                checked={worldPart === "USA"}
              />
              <div className="checkbox">
                <div className="checkbox-inside" />
              </div>
              <label for="world-part-USA">USA</label>
            </div>
            <div className="world-part">
              <input
                type="radio"
                id="world-part-Europe"
                name="worldPart"
                value="Europe"
                className="input-world-part"
                onChange={(e) => {
                  setWorldPart(e.target.value);
                  setRegion("");
                }}
              />
              <div className="checkbox">
                <div className="checkbox-inside" />
              </div>
              <label for="world-part-Europe">Europe</label>
            </div>
          </div>
          <div className="map-container">
            {maps[worldPart]}
            {regions[worldPart].map((item) => (
              <div
                key={item}
                className={`map-${worldPart}-${item}`}
                onClick={() => onMapClick(item)}
              />
            ))}
          </div>
        </section>
        <section className="input-container">
          <span className="label">4. Vendor</span>
          <div className="vendors">
            {Object.values(vendors).map((item) => (
              <div
                key={item.name}
                className={`vendor ${vendor === item.name ? "active" : ""}`}
                onClick={() => setVendor(item.name)}
              >
                {vendor === item.name ? item.coloredIcon : item.greyIcon}
              </div>
            ))}
          </div>
        </section>
      </main>
      <footer className="footer">
        <button
          disabled={!isFormFilled}
          className="submit-button"
          type="submit"
          onClick={(ev) => submitForm(ev)}
        >
          Next
        </button>
      </footer>
    </form>
  );
};

export default FormPage;
