import { ReactComponent as AmazonGreyIcon } from "../icons/vendors/amazon-grey.svg";
import { ReactComponent as AmazonColoredIcon } from "../icons/vendors/amazon-colored.svg";
import { ReactComponent as MicrosoftGreyIcon } from "../icons/vendors/microsoft-grey.svg";
import { ReactComponent as MicrosoftColoredIcon } from "../icons/vendors/microsoft-colored.svg";
import { ReactComponent as GoogleColoredIcon } from "../icons/vendors/google-colored.svg";
import { ReactComponent as GoogleGreyIcon } from "../icons/vendors/google-grey.svg";

import { ReactComponent as USAMap } from "../icons/usa.svg";
import { ReactComponent as EuropeMap } from "../icons/europe.svg";

export const sizes = ["small", "medium", "enterprise"];

export const regions = {
  USA: ["west", "midwest", "south", "northeast"],
  Europe: ["south", "west", "north", "central"],
};

export const maps = {
  USA: <USAMap />,
  Europe: <EuropeMap />,
};

export const mapColors = {
  active: "#FE0292",
  disabled: "#C7CDD9",
};

export const vendors = {
  amazon: {
    name: "amazon",
    greyIcon: <AmazonGreyIcon />,
    coloredIcon: <AmazonColoredIcon className="vendor-icon amazon" />,
  },
  microsoft: {
    name: "microsoft",
    greyIcon: <MicrosoftGreyIcon />,
    coloredIcon: <MicrosoftColoredIcon className="vendor-icon microsoft" />,
  },
  google: {
    name: "google",
    greyIcon: <GoogleGreyIcon />,
    coloredIcon: <GoogleColoredIcon className="vendor-icon google" />,
  },
};
