import { createSlice } from '@reduxjs/toolkit'

export const recommendationsReducer = createSlice({
  name: 'recommendations',
  initialState: {
    value: []
  },
  reducers: {
    add: (state, action) => {
      state.value = [...state.value, action.payload];
    },
    remove: (state, action) => {
     state.value = state.value.filter(el => el !== action.payload)
    },
    clear: (state) => {
      state.value = [];
    }
  },
})

export const { add, remove, clear } = recommendationsReducer.actions

export default recommendationsReducer.reducer