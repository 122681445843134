const recepient = 'sergey.belskiy@gmail.com';

export async function sendEmail(client, services) {
  const response = await fetch(
    `https://notification.eleks.dns-cloud.net/api/send?code=cwJOJUs1cx3CjUsH96wjHpfAs3zOqJdtEh9-K088BitVAzFuF5vZdQ==`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        to: recepient,
        client,
        services,
      }),
    }
  );

  if (response.status === 200) {
    return response.json();
  } else {
    return {
      error: "Error",
    };
  }
}
