import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { recomendations } from "../data/recomendations";
import { emissions } from "../data/emissions";
import { getCircle } from "../helpers/getCircle";
import { useSwipe } from "../hooks/useSwipe";
import { clear } from "../reducers/recommendations";
import Contact from "./Contact";
import {
  calculateServicesEmissions,
  calculateTotalEmissions,
} from "../helpers/calculateEmissions";
import ReactGA from 'react-ga4';

import { ReactComponent as ChevronLeftIcon } from "../icons/chevron-left.svg";
import { ReactComponent as LeafIcon } from "../icons/leaf.svg";
import { ReactComponent as CheckIcon } from "../icons/check.svg";
import { ReactComponent as ArrowUpIcon } from "../icons/arrow-up.svg";
import { ReactComponent as ArrowRightIcon } from "../icons/arrow-right.svg";
import { ReactComponent as CallIcon } from "../icons/call.svg";

import "../styles/MainPage.scss";

const MainPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const emissionsData = emissions[userData.worldPart][userData.region];
  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe();
  const [isContact, setIsContact] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const selectedRecommendations = useSelector(
    (state) => state.recommendations.value
  );

  const [activeHeader, setActiveHeader] = useState(
    selectedRecommendations.length !== 0 ? 1 : 0
  );

  const servicesEmissions = calculateServicesEmissions(emissionsData);
  const totalEmissions = calculateTotalEmissions(
    selectedRecommendations,
    emissionsData
  );

  const mainCircle = getCircle(emissionsData.averageEmissions, "white", userData.worldPart);
  const calculatedCircle = getCircle(totalEmissions, "white", userData.worldPart);
  const migrationEmissions = (
    emissionsData.averageEmissions *
    (1 - emissionsData.migrationRate / 100)
  ).toFixed(3);
  const migrationCircle = getCircle(migrationEmissions, "blue", userData.worldPart);
  const servicesCircles = Object.values(servicesEmissions).reduce(
    (acc, val) => ({ ...acc, [val.name]: getCircle(val.emission, "blue", userData.worldPart) }),
    {}
  );

  const goBack = () => {
    navigate("/form", { replace: true });
    sessionStorage.removeItem("userData");
    dispatch(clear());
  };

  const goToPreviousCard = () => {
    if (activeHeader !== 0) {
      setActiveHeader(activeHeader - 1);
    }
  };

  const goToNextCard = () => {
    if (
      selectedRecommendations.length !== 0 &&
      activeHeader < headers.length - 1
    ) {
      setActiveHeader(activeHeader + 1);
    }
  };

  const openContact = () => {
    const scroll = window.scrollY;
    const page = document.getElementById("MainPage");
    page.style.setProperty("--st", -scroll + "px");
    setScrollPosition(scroll);
    setIsContact(true);
    ReactGA.event({category: "Open contact form", action: "Open contact form"});
  };

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [isContact, scrollPosition]);

  const headers = [
    {
      subHeader: (
        <span>
          Your current average <br /> CPU Emission factor
        </span>
      ),
      greenPower: emissionsData.greenPower,
      emissions: emissionsData.averageEmissions,
      circle: mainCircle,
    },
    {
      subHeader: (
        <span>
          average with us <br /> CPU Emission factor
        </span>
      ),
      greenPower: selectedRecommendations.find((el) => el === "Migration")
        ? emissionsData.migratedGreenPower
        : emissionsData.greenPower,
      emissions: totalEmissions,
      circle: calculatedCircle,
    },
  ];

  return (
    <section
      id="MainPage"
      className={`MainPage ${isContact ? "noscroll" : ""}`}
    >
      <ChevronLeftIcon className="chevron-icon" onClick={goBack} />
      <CallIcon className="call-icon" onClick={openContact} />
      <div
        className="header-container"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={() => onTouchEnd(goToPreviousCard, goToNextCard)}
      >
        {headers.map((item, i) => (
          <header
            key={i}
            className="header"
            style={{ transform: `translateX(-${activeHeader * 100}%)` }}
          >
            <div className="progress-container">
              <div className="progress">
                <div
                  className="progress-filled"
                  style={{ width: item.greenPower }}
                />
              </div>
              <div className="progress-labels">
                <span className="progress-label">Green</span>
                <span className="progress-label">Non-green</span>
              </div>
            </div>
            {item.circle}
            <span className="emissions">{item.emissions}</span>
            <span className="metric">g of CO2e/h</span>
            <span className="sub-header">{item.subHeader}</span>
          </header>
        ))}
        <div className="dots">
          {selectedRecommendations.length !== 0 &&
            headers.map((item, i) => (
              <div
                key={i}
                className={`dot ${i === activeHeader ? "active" : ""}`}
              />
            ))}
        </div>
      </div>
      <main className="cards">
        <Link
          to={!!emissionsData.migrationRate ? `/migration` : `/main`}
          className="link"
        >
          <div className="card migration">
            <div className="circle-background" />
            <div className="circle-container">
              {!!emissionsData.migrationRate && (
                <>
                  {migrationCircle}
                  {selectedRecommendations.find((el) => el === "Migration") ? (
                    <CheckIcon className="circle-center" />
                  ) : (
                    <LeafIcon
                      width="60px"
                      height="60px"
                      className="circle-center"
                    />
                  )}
                </>
              )}
            </div>
            {!!emissionsData.migrationRate && (
              <div className="percents">
                <ArrowUpIcon />
                <span className="percent">{emissionsData.migrationRate}%</span>
              </div>
            )}
            {!emissionsData.migrationRate && (
              <div className="warning">
                <CheckIcon />
                <span className="warning-name">
                  best{" "}
                  <span className="warning-name bold">CPU Emission factor</span>
                </span>
              </div>
            )}
            <span className="sub-name">Engineering Services</span>
            <span className="card-name">Cloud Migration</span>
            <ArrowRightIcon className="arrow-icon" />
          </div>
        </Link>
        {Object.values(recomendations).map((recomendation) => (
          <Link
            key={recomendation.name}
            to={`/recommendations?name=${recomendation.name}`}
            className="link"
          >
            <div className="card">
              <div className="circle-background" />
              <div className="circle-container">
                {servicesCircles[recomendation.name]}
                {selectedRecommendations.find(
                  (el) => el === recomendation.name
                ) ? (
                  <CheckIcon className="circle-center" />
                ) : (
                  <LeafIcon
                    width="60px"
                    height="60px"
                    className="circle-center"
                  />
                )}
              </div>
              <div className="percents">
                <ArrowUpIcon />
                <span className="percent">up to {recomendation.percents}%</span>
              </div>
              <span className="card-name">{recomendation.name}</span>
              <ArrowRightIcon className="arrow-icon" />
            </div>
          </Link>
        ))}
        <button className="contact-button" onClick={openContact}>
          Next steps
        </button>
      </main>
      <Contact isContact={isContact} setIsContact={setIsContact} />
    </section>
  );
};

export default MainPage;
