import { recomendations } from "../data/recomendations";

export const calculateServicesEmissions = (data) =>
  Object.values(recomendations).reduce(
    (acc, val) => ({
      ...acc,
      [val.name]: {
        name: val.name,
        emission: (data.averageEmissions * (1 - val.percents / 100)).toFixed(3),
      }
    }),
    {}
  );

export const calculateTotalEmissions = (selectedServices, data) => {
  const totalEmissions = selectedServices.reduce((acc, el) => {
    if (el === "Migration") {
      return acc * (1 - data.migrationRate / 100);
    } else {
      return acc * (1 - recomendations[el].percents / 100);
    }
  }, data.averageEmissions);

  return totalEmissions.toFixed(3);
};
