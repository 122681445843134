import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import IntroPage from "./IntroPage";
import WelcomePage from "./WelcomePage";
import FormPage from "./FormPage";
import GeneratingPage from "./GeneratingPage";
import MainPage from "./MainPage";
import RecommendationsPage from "./RecommendationsPage";
import MigrationPage from "./MigrationPage";

import "../styles/App.scss";

const App = () => {
  const [data, setData] = useState(JSON.parse(sessionStorage.getItem("userData")));
  const location = useLocation();

  useEffect(() => {
    setData(JSON.parse(sessionStorage.getItem("userData")));
  }, [location])

  return (
    <Routes>
      <Route index element={<IntroPage />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/form" element={<FormPage />} />
      <Route path="/generating" element={<GeneratingPage />} />
      <Route
        path="/main"
        element={data ? <MainPage /> : <Navigate to="/welcome" />}
      />
      <Route
        path="/recommendations"
        element={
          data ? <RecommendationsPage /> : <Navigate to="/welcome" />
        }
      />
      <Route
        path="/migration"
        element={data ? <MigrationPage /> : <Navigate to="/welcome" />}
      />
    </Routes>
  );
};

export default App;
