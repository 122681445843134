import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as EleksIcon } from "../icons/eleks.svg";
import { ReactComponent as SpinnerIcon } from "../icons/spinner.svg";

import "../styles/IntroPage.scss";

const IntroPage = () => {
  const navigate = useNavigate();

  setTimeout(() => {
    navigate("/welcome", { replace: true });
  }, 3000);

  return (
    <section className="IntroPage">
      <EleksIcon className="eleks-logo" />
      <SpinnerIcon className="spinner" />
    </section>
  );
};

export default IntroPage;
