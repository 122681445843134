import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ChevronLeftIcon } from "../icons/chevron-left.svg";
import { ReactComponent as LoaderIcon } from "../icons/loader.svg";
import { ReactComponent as DakarIcon } from "../icons/dakar.svg";
import { ReactComponent as EDFIcon } from "../icons/edf.svg";
import { ReactComponent as EDFStorageIcon } from "../icons/edf-storage.svg";
import { ReactComponent as EDFCalculatorIcon } from "../icons/edf-calculator.svg";
import { ReactComponent as SpinnerIcon } from "../icons/spinner.svg";

import "../styles/GeneratingPage.scss";

const GeneratingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const navigationTimeout = setTimeout(() => {
    setIsLoading(false);
  }, 7000);

  const goBack = () => {
    clearTimeout(navigationTimeout);
    navigate("/form", { replace: true });
  };

  return (
    <section className="GeneratingPage">
      <ChevronLeftIcon className="chevron-icon" onClick={goBack} />
      <div className="card">
        <span className="heading">
          Generating <br /> recommendations
        </span>
        <LoaderIcon width="100%" />
        <div className="service dakar">
          <DakarIcon />
          <span className="service-name">Dakar</span>
        </div>
        <div className="service edf">
          <EDFIcon />
          <span className="service-name">EDF – Eleks Data Flow</span>
        </div>
        <div className="service edf-storage">
          <EDFStorageIcon />
          <span className="service-name">EDF Data storage</span>
        </div>
        <div className="service edf-calculator">
          <EDFCalculatorIcon />
          <span className="service-name">
            EDF cloud carbon <br /> footprint calculator
          </span>
        </div>
      </div>
      <button
        onClick={() => navigate("/main", { replace: true })}
        className={`button ${isLoading ? "disabled" : "active"}`}
      >
        {isLoading ? (
          <SpinnerIcon width="30px" height="30px" />
        ) : (
          "Recommendation"
        )}
      </button>
    </section>
  );
};

export default GeneratingPage;
