import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { recomendations } from "../data/recomendations";
import { emissions } from "../data/emissions";
import { useSwipe } from "../hooks/useSwipe";
import { add, remove } from "../reducers/recommendations";
import { getCircle } from "../helpers/getCircle";
import { calculateServicesEmissions } from "../helpers/calculateEmissions";
import Services from "./Services";
import Contact from "./Contact";
import ReactGA from 'react-ga4';

import { ReactComponent as ChevronLeftIcon } from "../icons/chevron-left.svg";
import { ReactComponent as SmallArrowIcon } from "../icons/arrow-right-pink-small.svg";
import { ReactComponent as BigArrowIcon } from "../icons/arrow-right-pink-big.svg";

import "../styles/RecommendationsPage.scss";

const RecommendationsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const recomendationName = searchParams.get("name");
  const recomendation = recomendations[recomendationName];

  const [isContact, setIsContact] = useState(false);
  const [activeService, setActiveService] = useState(null);
  const [activeCard, setActiveCard] = useState(recomendation.order);
  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe();

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const emissionsData = emissions[userData.worldPart][userData.region];

  const selectedRecommendations = useSelector(
    (state) => state.recommendations.value
  );
  const dispatch = useDispatch();

  const mainCircle = getCircle(emissionsData.averageEmissions, "white", userData.worldPart);
  const servicesEmissions = calculateServicesEmissions(emissionsData);
  const data = Object.values(servicesEmissions).reduce(
    (acc, val) => ({
      ...acc,
      [val.name]: {
        emission: val.emission,
        circle: getCircle(val.emission, "white", userData.worldPart),
      },
    }),
    {}
  );

  const goBack = () => {
    navigate("/main", { replace: true });
  };

  const goToPreviousCard = () => {
    if (activeCard !== 0 && !activeService) {
      setActiveCard(activeCard - 1);
    }
  };

  const goToNextCard = () => {
    if (
      activeCard < Object.values(recomendations).length - 1 &&
      !activeService
    ) {
      setActiveCard(activeCard + 1);
    }
  };

  useEffect(() => {
    if (activeService || isContact) {
      document.body.setAttribute("class", "noscroll");
    }

    if (!activeService && !isContact) {
      document.body.removeAttribute("class");
    }
  }, [activeService, isContact]);

  const openContact = () => {
    ReactGA.event({category: "Open contact form", action: "Open contact form"});
    setIsContact(true);
  }

  return (
    <section
      className={`RecommendationsPage ${
        activeService || isContact ? "noscroll" : ""
      }`}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={() => onTouchEnd(goToPreviousCard, goToNextCard)}
    >
      <ChevronLeftIcon className="chevron-icon" onClick={goBack} />
      <div className="emissions-changes">
        <span className="emission">{emissionsData.averageEmissions}</span>
        <SmallArrowIcon />
        <span className="emission">
          {Object.values(data)[activeCard].emission}
        </span>
      </div>
      <main className="cards">
        {Object.values(recomendations).map((item) => {
          const services = Object.values(recomendations[item.name].services);
          const isCardSelected = selectedRecommendations.find(
            (el) => el === item.name
          );

          const onButtonClick = () => {
            if (isCardSelected) {
              dispatch(remove(item.name));
            } else {
              dispatch(add(item.name));
            }
          };

          return (
            <div
              key={item.name}
              className={`card ${activeCard === item.order ? "active" : ""}`}
              style={{ transform: `translateX(-${activeCard * 103}%)` }}
            >
              <div className="card-carpet" />
              <div className="card-content">
                <header className="card-header">
                  <span className="card-name">{item.name}</span>
                  <div className="circle-changes">
                    {mainCircle}
                    <span className="percents">up to {item.percents} %</span>
                    <BigArrowIcon />
                    {data[item.name].circle}
                  </div>
                </header>
                <main className="recommendations">
                  {services.map((service) => (
                    <div
                      key={service.name}
                      className="recommendation"
                      onClick={() =>
                        setActiveService({
                          name: service.name,
                          services: service.subservices,
                        })
                      }
                    >
                      {service.icon}
                      <span className="recommendation-name">
                        {service.name}
                      </span>
                    </div>
                  ))}
                </main>
                <button
                  className={`card-button ${isCardSelected ? "discard" : ""}`}
                  onClick={onButtonClick}
                >
                  {isCardSelected ? "- Added" : "+ Add"}
                </button>
              </div>
            </div>
          );
        })}
      </main>
      <span className="footer">
        not sure?{" "}
        <span className="footer-link" onClick={openContact}>
          book a call
        </span>
      </span>
      <Services
        activeService={activeService}
        setActiveService={setActiveService}
      />
      <Contact isContact={isContact} setIsContact={setIsContact} />
    </section>
  );
};

export default RecommendationsPage;
