import { emissionsExtreme } from "./constants";

import { ReactComponent as Circle0Icon } from "../icons/circles-white/circle-0.svg";
import { ReactComponent as Circle1Icon } from "../icons/circles-white/circle-1.svg";
import { ReactComponent as Circle2Icon } from "../icons/circles-white/circle-2.svg";
import { ReactComponent as Circle3Icon } from "../icons/circles-white/circle-3.svg";
import { ReactComponent as Circle4Icon } from "../icons/circles-white/circle-4.svg";
import { ReactComponent as Circle5Icon } from "../icons/circles-white/circle-5.svg";
import { ReactComponent as Circle6Icon } from "../icons/circles-white/circle-6.svg";
import { ReactComponent as Circle7Icon } from "../icons/circles-white/circle-7.svg";
import { ReactComponent as Circle8Icon } from "../icons/circles-white/circle-8.svg";

import { ReactComponent as CircleBlue0Icon } from "../icons/circles-blue/circle-0.svg";
import { ReactComponent as CircleBlue1Icon } from "../icons/circles-blue/circle-1.svg";
import { ReactComponent as CircleBlue2Icon } from "../icons/circles-blue/circle-2.svg";
import { ReactComponent as CircleBlue3Icon } from "../icons/circles-blue/circle-3.svg";
import { ReactComponent as CircleBlue4Icon } from "../icons/circles-blue/circle-4.svg";
import { ReactComponent as CircleBlue5Icon } from "../icons/circles-blue/circle-5.svg";
import { ReactComponent as CircleBlue6Icon } from "../icons/circles-blue/circle-6.svg";
import { ReactComponent as CircleBlue7Icon } from "../icons/circles-blue/circle-7.svg";
import { ReactComponent as CircleBlue8Icon } from "../icons/circles-blue/circle-8.svg";

const circles = {
  white: {
    0: <Circle0Icon className="circle" />,
    1: <Circle1Icon className="circle" />,
    2: <Circle2Icon className="circle" />,
    3: <Circle3Icon className="circle" />,
    4: <Circle4Icon className="circle" />,
    5: <Circle5Icon className="circle" />,
    6: <Circle6Icon className="circle" />,
    7: <Circle7Icon className="circle" />,
    8: <Circle8Icon className="circle" />,
  },
  blue: {
    0: <CircleBlue0Icon className="circle" />,
    1: <CircleBlue1Icon className="circle" />,
    2: <CircleBlue2Icon className="circle" />,
    3: <CircleBlue3Icon className="circle" />,
    4: <CircleBlue4Icon className="circle" />,
    5: <CircleBlue5Icon className="circle" />,
    6: <CircleBlue6Icon className="circle" />,
    7: <CircleBlue7Icon className="circle" />,
    8: <CircleBlue8Icon className="circle" />,
  },
};

export const getCircle = (emission, color, worldPart) => {
  const { min, max } = emissionsExtreme[worldPart];
  const diff = max - min;
  const delta = (emission - min) * 9;
  const range = 8 - Math.floor(delta / diff);
  const res = range > 8 ? 8 : range;

  return circles[color][res];
};
