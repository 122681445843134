export const googleAnalyticsKey = "G-9MN13WJGD9";

export const emissionsExtreme = {
  USA: {
    min: 0.406,
    max: 1.95,
  },
  Europe: {
    min: 0.018,
    max: 1.647,
  },
};
