import React, { useState } from "react";
import { useSelector } from "react-redux";
import { sendEmail } from "../api/email";

import { ReactComponent as LoaderIcon } from "../icons/white-loader.svg";

import "../styles/Contact.scss";

const Contact = ({ isContact, setIsContact }) => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const services = useSelector((state) => state.recommendations.value);

  const onSubmit = async () => {
    if (email) {
      setIsLoading(true);
      const client = `Email: ${email}, Comment: "${comment}", Company: ${userData.name}, Size: ${userData.size}, Region: ${userData.region}, Vendor: ${userData.vendor}`;
      const response = await sendEmail(client, services.join(", "));
      if (!response.error) {
        setIsLoading(false);
        setIsContact(false);
      }
    }
  };

  return (
    <section className={`Contact ${isContact ? "visible" : "hidden"}`}>
      {isContact && (
        <div className="contact-carpet" onClick={() => setIsContact(false)} />
      )}
      <section className="contact-card">
        <main className="contact-info">
          <span className="contact-sub-heading">Contact us</span>
          <span className="contact-heading">Book a call with us</span>
          <span className="contact-label">1. Company email</span>
          <input
            className="contact-email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            className="contact-comment"
            placeholder="Enter your comment here"
            onChange={(e) => setComment(e.target.value)}
          />
          <span className="contact-hint">
            We will contact you within 48 hours
          </span>
        </main>
        <footer className="contact-footer">
          <button className="contact-close" onClick={onSubmit}>
            {isLoading ? <LoaderIcon className="loader-icon" /> : "Send"}
          </button>
        </footer>
      </section>
    </section>
  );
};

export default Contact;
