import { ReactComponent as USAWest } from "../icons/maps/usa-west.svg";
import { ReactComponent as USAMidwest } from "../icons/maps/usa-midwest.svg";
import { ReactComponent as USANortheast } from "../icons/maps/usa-northeast.svg";

import { ReactComponent as EuropeWest } from "../icons/maps/europe-west.svg";
import { ReactComponent as EuropeNorth } from "../icons/maps/europe-north.svg";

export const emissions = {
  USA: {
    west: {
      averageEmissions: 0.997,
      greenPower: "40.6%",
      migrationRate: 59,
      migratedGreenPower: "65%",
      migrationLocation: "Oregon",
      migrationVendor: "google",
      map: <USAWest className="migration-map usa" />,
    },
    midwest: {
      averageEmissions: 1.431,
      greenPower: "57.3%",
      migrationRate: 16,
      migratedGreenPower: "57.3%",
      migrationLocation: "Des Moines, Iowa",
      migrationVendor: "microsoft",
      map: <USAMidwest className="migration-map usa" />,
    },
    south: {
      averageEmissions: 1.005,
      greenPower: "24.1%",
      migrationRate: 0,
      migratedGreenPower: "24.1%",
    },
    northeast: {
      averageEmissions: 1.151,
      greenPower: "7.9%",
      migrationRate: 15,
      migratedGreenPower: "7.9%",
      migrationLocation: "N. Virginia",
      migrationVendor: "amazon",
      map: <USANortheast className="migration-map usa" />,
    },
  },
  Europe: {
    west: {
      averageEmissions: 0.726,
      greenPower: "15.5%",
      migrationRate: 86,
      migratedGreenPower: "28.0%",
      migrationLocation: "Switzerland",
      migrationVendor: "google",
      map: <EuropeWest className="migration-map europe" />,
    },
    north: {
      averageEmissions: 0.483,
      greenPower: "45.3%",
      migrationRate: 96,
      migratedGreenPower: "68.0%",
      migrationLocation: "Sweden",
      migrationVendor: "amazon",
      map: <EuropeNorth className="migration-map europe" />,
    },
    central: {
      averageEmissions: 0.799,
      greenPower: "18.0%",
      migrationRate: 0,
      migratedGreenPower: "18.0%",
    },
    south: {
      averageEmissions: 0.551,
      greenPower: "20.0%",
      migrationRate: 0,
      migratedGreenPower: "20.0%",
    },
  },
};
