import React, { useState } from "react";
import { Link } from "react-router-dom";
import Policy from "./Policy";

import { ReactComponent as WelcomeIcon } from "../icons/welcome.svg";

import "../styles/WelcomePage.scss";

const WelcomePage = () => {
  const [isPolicy, setIsPolicy] = useState(false);

  const onPolicyClick = () => {
    setIsPolicy(!isPolicy);
  };

  return (
    <section className="WelcomePage">
      <WelcomeIcon className="welcome-icon" />
      <div className="info-section">
        <div>
          <span className="heading">CO2e reduced</span>
          <ul className="sub-heading">
            <li>
              Get comprehensive recommendations for improving your carbon
              footprint.
            </li>
            <li>
              Interested in improving your public data center's green rate with
              alternative options for its migration? Just let us know what
              region it is located in.
            </li>
            <li>
              Want to know the indicators of its carbon footprint and renewals &
              non-renewable energy sources of the components of its formation?
            </li>
          </ul>
        </div>
        <div>
          <span className="policy">
            See our{" "}
            <span onClick={onPolicyClick} className="policy-link">
              privacy policy
            </span>{" "}
            for full details
          </span>
          <Link className="start-button" to="/form">
            Start
          </Link>
        </div>
      </div>
      <Policy isVisible={isPolicy} onPolicyClick={onPolicyClick} />
    </section>
  );
};

export default WelcomePage;
