import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emissions } from "../data/emissions";
import { vendors } from "../data/form";
import { add, remove } from "../reducers/recommendations";
import { getCircle } from "../helpers/getCircle";
import Contact from "./Contact";
import ReactGA from "react-ga4";

import { ReactComponent as ChevronLeftIcon } from "../icons/chevron-left.svg";
import { ReactComponent as SmallArrowIcon } from "../icons/arrow-right-pink-small.svg";
import { ReactComponent as BigArrowIcon } from "../icons/arrow-right-pink-big.svg";
import { ReactComponent as MapPinIcon } from "../icons/map-pin.svg";
import { ReactComponent as LeafIcon } from "../icons/leaf.svg";

import "../styles/MigrationPage.scss";

const MigrationPage = () => {
  const navigate = useNavigate();
  const [isContact, setIsContact] = useState(false);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const emissionsData = emissions[userData.worldPart][userData.region];
  const migrationEmissions = (
    emissionsData.averageEmissions *
    (1 - emissionsData.migrationRate / 100)
  ).toFixed(3);
  const migrationCircle = getCircle(
    migrationEmissions,
    "blue",
    userData.worldPart
  );

  const selectedRecommendations = useSelector(
    (state) => state.recommendations.value
  );
  const isCardSelected = selectedRecommendations.find(
    (el) => el === "Migration"
  );
  const isCardTopClipped =
    userData.worldPart === "Europe" && userData.region === "west";

  const dispatch = useDispatch();

  const mainCircle = getCircle(
    emissionsData.averageEmissions,
    "blue",
    userData.worldPart
  );

  const goBack = () => {
    navigate("/main", { replace: true });
  };

  const onButtonClick = () => {
    if (isCardSelected) {
      dispatch(remove("Migration"));
    } else {
      dispatch(add("Migration"));
    }
  };

  const openContact = () => {
    ReactGA.event({
      category: "Open contact form",
      action: "Open contact form",
    });
    setIsContact(true);
  };

  useEffect(() => {
    if (isContact) {
      document.body.setAttribute("class", "noscroll");
    } else {
      document.body.removeAttribute("class");
    }
  }, [isContact]);

  return (
    <section className={`MigrationPage ${isContact ? "noscroll" : ""}`}>
      <ChevronLeftIcon className="chevron-icon" onClick={goBack} />
      <div className="emissions-changes">
        <span className="emission">{emissionsData.averageEmissions}</span>
        <SmallArrowIcon />
        <span className="emission">{migrationEmissions}</span>
      </div>
      <div className="card">
        <header className={`card-header ${isCardTopClipped ? "clipped" : ""}`}>
          <div className="card-location">
            <MapPinIcon />
            <span className="location-name">
              to {emissionsData.migrationLocation}
            </span>
          </div>
          {emissionsData.map}
        </header>
        <main className="recommendations">
          <span className="card-subheader">Engineering Services</span>
          <span className="card-name">
            cloud migration {userData.region} region
          </span>
          <div className="circle-changes">
            <div className="circle-change">
              <span className="label">current</span>
              <div className="circle">
                {mainCircle}
                <LeafIcon className="leaf-icon" />
              </div>
              {vendors[userData.vendor].coloredIcon}
            </div>
            <div className="percents">
              <span>up to {emissionsData.migrationRate}%</span>
              <BigArrowIcon />
            </div>
            <div className="circle-change">
              <span className="label">best</span>
              <div className="circle">
                {migrationCircle}
                <LeafIcon className="leaf-icon" />
              </div>
              {vendors[emissionsData.migrationVendor].coloredIcon}
            </div>
          </div>
          <button
            className={`card-button ${isCardSelected ? "discard" : ""}`}
            onClick={onButtonClick}
          >
            {isCardSelected ? "- Added" : "+ Add"}
          </button>
        </main>
      </div>
      <span className="footer">
        not sure?{" "}
        <span className="footer-link" onClick={openContact}>
          book a call
        </span>
      </span>
      <Contact isContact={isContact} setIsContact={setIsContact} />
    </section>
  );
};

export default MigrationPage;
