import React, { useEffect, useState } from "react";

import "../styles/Services.scss";

const Services = ({ activeService, setActiveService }) => {
  const [isVisible, setIsVisible] = useState(null);

  useEffect(() => setIsVisible(activeService), [activeService, setIsVisible]);

  const onClose = () => {
    setIsVisible(null);
    setTimeout(() => setActiveService(null), 500);
  };

  return (
    <section className={`Services ${isVisible ? "visible" : "hidden"}`}>
      {activeService && <div className="services-carpet" onClick={onClose} />}
      <section className="services-card">
        <main className="services-info">
          <span className="services-sub-heading">
            {activeService && activeService.name}
          </span>
          {activeService &&
            activeService.services.map((service) => (
              <div className="services-service" key={service.name}>
                <span className="services-heading">{service.name}</span>
                <div className="services-description">
                  <div>{service.icon}</div>
                  <p className="services-text">{service.description}</p>
                </div>
              </div>
            ))}
        </main>
        <footer className="services-footer">
          <button className="services-close" onClick={onClose}>
            Close
          </button>
        </footer>
      </section>
    </section>
  );
};

export default Services;
