//Advisory
import { ReactComponent as Advisory1 } from "../icons/services/advisory1.svg";
import { ReactComponent as Advisory2 } from "../icons/services/advisory2.svg";
import { ReactComponent as Advisory3 } from "../icons/services/advisory3.svg";
import { ReactComponent as Advisory4 } from "../icons/services/advisory4.svg";

//Engineering
import { ReactComponent as Engineering1 } from "../icons/services/engineering1.svg";
import { ReactComponent as Engineering2 } from "../icons/services/engineering2.svg";
import { ReactComponent as Engineering3 } from "../icons/services/engineering3.svg";
import { ReactComponent as Engineering4 } from "../icons/services/engineering4.svg";

//Optimization
import { ReactComponent as Optimization1 } from "../icons/services/optimization1.svg";
import { ReactComponent as Optimization2 } from "../icons/services/optimization2.svg";
import { ReactComponent as Optimization3 } from "../icons/services/optimization3.svg";

//Services
import { ReactComponent as Service1 } from "../icons/services/service1.svg";
import { ReactComponent as Service2 } from "../icons/services/service2.svg";
import { ReactComponent as Service3 } from "../icons/services/service3.svg";
import { ReactComponent as Service4 } from "../icons/services/service4.svg";
import { ReactComponent as Service5 } from "../icons/services/service5.svg";
import { ReactComponent as Service6 } from "../icons/services/service6.svg";
import { ReactComponent as Service7 } from "../icons/services/service7.svg";
import { ReactComponent as Service8 } from "../icons/services/service8.svg";
import { ReactComponent as Service9 } from "../icons/services/service9.svg";
import { ReactComponent as Service10 } from "../icons/services/service10.svg";
import { ReactComponent as Service11 } from "../icons/services/service11.svg";
import { ReactComponent as Service12 } from "../icons/services/service12.svg";
import { ReactComponent as Service13 } from "../icons/services/service13.svg";
import { ReactComponent as Service14 } from "../icons/services/service14.svg";
import { ReactComponent as Service15 } from "../icons/services/service15.svg";
import { ReactComponent as Service16 } from "../icons/services/service16.svg";
import { ReactComponent as Service17 } from "../icons/services/service17.svg";
import { ReactComponent as Service18 } from "../icons/services/service18.svg";
import { ReactComponent as Service19 } from "../icons/services/service19.svg";
import { ReactComponent as Service20 } from "../icons/services/service20.svg";
import { ReactComponent as Service21 } from "../icons/services/service21.svg";
import { ReactComponent as Service22 } from "../icons/services/service22.svg";

export const recomendations = {
  "Engineering Services": {
    name: "Engineering Services",
    percents: 80,
    order: 0,
    services: {
      "Application Development": {
        name: "Application Development",
        icon: <Engineering1 />,
        subservices: [
          {
            name: "Desktop software",
            icon: <Service7 />,
            description:
              "We provide full-cycle application development services with desktop app integration for a wide variety of operating systems, including Windows, macOS and Linux. Our development team offers tailored support at every stage of your development process, helping you deliver your end-to-end custom software project on time and adapt it to use less computing resources by maximizing hardware utilization.",
          },
          {
            name: "Web applications",
            icon: <Service8 />,
            description:
              "ELEKS’ web application development service covers every element of a successful web solution, from technology consulting and UX design, to front and back-end development, deployment and support and reducing carbon emissions by selecting the right innovative cloud-native services for web applications.",
          },
          {
            name: "Custom Mobile apps",
            icon: <Service9 />,
            description:
              "We offer end-to-end mobile application development services that are tailored to your business needs – from ideation all the way through to launch. Our mobile development team has a solid track record of delivering native and cross-platform custom applications for iOS and Android. As mobile phones are part of carbon emissions, with ELEKS mobile apps you get flawless performance including CO2 reduction, robust security and an enhanced user experience across platforms and devices.",
          },
          {
            name: "Data warehouse and BI Solutions",
            icon: <Service10 />,
            description:
              "Reducing CO2 emissions in data warehouse solutions is a multifaceted effort that involves optimizing both hardware and software, making environmentally conscious choices in data center operations, and continuously monitoring and improving efficiency. We're experts at delivering advanced DWH and BI solutions across different domains – from finance and insurance to logistics. Our solutions allow you to harness your data to enhance operations and customer service, while boosting revenue and providing strategic decision-makers with actionable insights.",
          },
        ],
      },
      "Application re‑engineering": {
        name: "Application re‑engineering",
        icon: <Engineering2 />,
        subservices: [
          {
            name: "Requirements and scoping",
            icon: <Service11 />,
            description:
              "We’ll get to know your business inside out, so we can define which application improvements will boost efficiency and performance with low CO2 impact. Whether it’s designing a more usable interface, helping you automate operations or moving services to the cloud.",
          },
          {
            name: "Application re-engineering",
            icon: <Service12 />,
            description:
              "Once we’ve scoped your requirements, budget and timeline, our team of software engineers will design and test robust application re-engineering solutions with your current legacy setup by making energy-efficient choices and leveraging modern technologies that significantly reduce the carbon footprint of your applications afterword. We’ll only make updates and adjustments that really add value.",
          },
          {
            name: "Launch and support",
            icon: <Service13 />,
            description:
              "Our engineers deploy, configure, and launch your re-engineered solution to your environment: on-premise, via the Cloud, or through Android/iOS app store for Mobile apps. You’ll benefit from ELEKS’ L2/L3 Support services, monitoring current CO2 metrics, reducing your maintenance costs and letting you focus your team on BAU.",
          },
        ],
      },
      "Cloud Migration": {
        name: "Cloud Migration",
        icon: <Engineering3 />,
        subservices: [
          {
            name: "Assessment and design",
            icon: <Service14 />,
            description:
              "We work with you to categorise your applications and design a new infrastructure and migration plan by using cloud and adopting energy-efficient practices. Afterall you can reduce the carbon footprint of your IT operations and take advantage of the sustainability efforts of cloud providers to contribute to a greener and more environmentally friendly computing environment.",
          },
          {
            name: "Implementation",
            icon: <Service15 />,
            description:
              "We define a comprehensive migration plan that creates the most seamless transfer, without disruption to your core business, using tried and tested DevOps methodologies and practices to manage your infrastructure.",
          },
          {
            name: "Optimisation",
            icon: <Service16 />,
            description:
              "We use the cloud optimisation phase to consider geo location-specific pricing, better carbon footprint of services and data storage; scaling scenarios and containerising your critical applications. This gives you the most appropriate Cloud-based solution for your needs.",
          },
          {
            name: "Support",
            icon: <Service10 />,
            description:
              "Once we’ve implemented a successful migration, we’ll help you maintain and manage your recourses, applications and data, through a centralised monitoring system.",
          },
        ],
      },
      "Smart Team": {
        name: "Smart Team",
        icon: <Engineering4 />,
        subservices: [
          {
            name: "How ELEKS’ Smart Team Works",
            icon: <Service17 />,
            description:
              "We’ll integrate a dedicated team of ELEKS software experts, allowing you to rapidly scale your development capabilities and deliver your project.",
          },
        ],
      },
    },
  },
  "Advisory Services": {
    name: "Advisory Services",
    percents: 30,
    order: 1,
    services: {
      "Sustainability Consulting": {
        name: "Sustainability Consulting",
        icon: <Advisory1 />,
        subservices: [
          {
            name: "Technology market research",
            icon: <Service1 />,
            description:
              "Our team will analyse existing sustainability solutions from a business and technology perspective to help you select those that will enhance your market offering and operations in the most seamless and profitable way.",
          },
          {
            name: "Advisory on GHG management using our sustainability platform",
            icon: <Service2 />,
            description:
              "We'll provide you with a comprehensive consultancy on how to facilitate GHG accounting, reduction, and removal to achieve transparency, traceability, and efficiency in reaching your net zero goals. We will support you with our ready-to-use IT solution for carbon management and can further customize our solution to meet your specific needs.",
          },
          {
            name: "Sustainability-led opportunity scoping",
            icon: <Service3 />,
            description:
              "We’ll help you link the newest technologies and market trends with your expertise to pinpoint new business opportunities and revenue streams that focus on sustainability.",
          },
        ],
      },
      "Data Strategy Consulting": {
        name: "Data Strategy",
        icon: <Advisory2 />,
        subservices: [
          {
            name: "Data Audit",
            icon: <Service4 />,
            description:
              "Get a comprehensive view of your company's data to optimise efficiencies, improve risk management and enhance capacity planning practices. We’ll holistically evaluate your organisation’s infrastructure, technologies, policies, procedures and processes, providing you with a report and recommendations on how to optimize database queries, indexing, and caching to reduce the computational resources needed for data processing.",
          },
          {
            name: "Data Management,BI and insights",
            icon: <Service5 />,
            description:
              "Turn your data into business-boosting intelligence with ELEKS’ expert guidance. We’ll help you design and implement a solid organisation-wide BI solution with comprehensive dashboards and use cloud-native storage solutions that automatically optimize data storage and retrieval, reducing the energy needed for data management. By harnessing these insights, you’ll be able to evaluate the performance of your products, assets and operations whilst developing a better understanding of your customers to make smarter decisions and gain a competitive market advantage.",
          },
          {
            name: "Data monetisation",
            icon: <Service6 />,
            description:
              "Your data is a valuable asset that will maximise your business results and give you access to new revenue streams. With the help of our experts, you can design and implement a company-wide data monetisation strategy to explore profitable untapped potential. We’ll give you the tools and techniques to establish data monetisation projects with an agile POC-like approach and a short feedback cycle, so you can easily kick-off new initiatives and improve existing ones to boost business outcomes and ROI.",
          },
        ],
      },
      "Technical Feasibility Study": {
        name: "Technical Feasibility Study",
        icon: <Advisory3 />,
        subservices: [
          {
            name: "Initial research",
            icon: <Service7 />,
            description:
              "Our R&D team conducts exhaustive research that allows us to get to the crux of your business problem/s and help you can identify opportunities and goals to reduce CO2 emissions, minimize environmental impact, and make informed decisions that support sustainable and responsible project development. We’ll then build you a team that’s best equipped to deliver it.",
          },
          {
            name: "Hypothesising and testing",
            icon: <Service8 />,
            description:
              "We’ll use our expertise in to come up with problem-solving hypotheses. Then we’ll conduct a stringent set of tests to validate those hypotheses, so we can help you define the best technological approach. As an example could be using renewable energy sources, such as solar, wind, or hydroelectric power, to meet the energy needs of the project. Consider the carbon reduction potential and cost-effectiveness of these alternatives.",
          },
          {
            name: "Selecting the right technology",
            icon: <Service9 />,
            description:
              "We’ll evaluate the use of energy-efficient technologies and systems, that could meet your specific business requirements, using our extensive knowledge to scope out the advantages and disadvantages of innovative technologies and to minimize energy consumption and CO2 emissions.",
          },
          {
            name: "Deployment and next steps",
            icon: <Service10 />,
            description:
              "Our experts will help you navigate deployment and ensure easy uptake of technologies and processes. Our Centers of Excellence encompass project management, BA, QA, customer support and more – with dedicated R&D management and engineers assigned to your feasibility study. So we’re well-equipped to support you as you adapt to change.",
          },
        ],
      },
      "Product Strategy Consulting": {
        name: "Product Strategy Consulting",
        icon: <Advisory4 />,
        subservices: [
          {
            name: "Reduced time to market",
            icon: <Service13 />,
            description:
              "Our multidisciplinary product team employs industry-defining expertise to help companies design, strategize, launch and manage complex, cross-domain products faster. Our end-to-end development process  empowers you to meet business goals quicker and with better cost-efficiency and helping to reduce CO2 emissions and support sustainability goals. This approach can also enhance your brand's reputation and appeal to environmentally conscious consumers.",
          },
          {
            name: "Advanced technological design",
            icon: <Service14 />,
            description:
              "We engineer savvy technical solutions to empower your organization to initiate and maintain more effective business processes that drive results. As a proven, end-to-end vendor, ELEKS Digital can help you design, launch, and manage multiple complex, cross-domain solutions. As your bandwidth needs expand, we help you optimize performance and remain future-focused by providing zero net emissions roadmap. Our solutions take advantage of state-of-the-art technologies like AI, AR/VR, IoT and Blockchain to drive impact throughout the entire organization.",
          },
          {
            name: "Customer-centricity",
            icon: <Service9 />,
            description:
              "Our team has decades of experience building and delivering award-winning digital products and eco-friendly solutions uniquely tailored to scale your business while prioritizing cost efficiency and flexibility. We perform holistic analyses of your internal operations and deliverables, evaluating end-users, workflows, processes, and experience gaps to help you identify problems and engineer scalable solutions. We apply test-and-learn iterative approaches to boost innovation, measure and improve every element of the customer experience.",
          },
          {
            name: "Actionable product roadmap",
            icon: <Service7 />,
            description:
              "Our iterative and collaborative process enables us to test and verify each hypothesis, prioritize durability and quality to ensure that products have a longer lifespan, reducing the frequency of replacements and associated emissions and determine what product strategy best supports your business goals, incorporating scenario planning to ensure that your strategy can easily adapt to market changes. From there, we’ll build a collaborative product roadmap based on your budget, needs, and priorities.",
          },
          {
            name: "Efficient and consistent business processes",
            icon: <Service3 />,
            description:
              "Using a combination of your product roadmap, as well as employing insights and knowledge gained from each iterative step of our process, we’ll continue to evaluate and redefine effective product design and management processes. We have a proven and efficient approach to business design and product management. Our digital transformation process will result in better design products that are energy-efficient during both production and use. Using energy-efficient components we will optimize power consumption, and incorporate sleep or standby modes to minimize energy waste.",
          },
          {
            name: "Effective portfolio management",
            icon: <Service21 />,
            description:
              "Real innovation requires ongoing iteration. ELEKS Digital helps engineer product strategies and management, and can leverage performance data to continue evaluating processes even after a delivered product/service. Our teams function as evergreen consultants, helping you analyze and innovate with our product portfolio management solutions. We’ll also help you transform your existing products into solutions that supplement your streamlined solutions. Best of all, relying on ELEKS Digital to drive your technology suite will empower you to focus on your core business.",
          },
        ],
      },
    },
  },
  "Optimization Services": {
    name: "Optimization Services",
    percents: 20,
    order: 2,
    services: {
      "Software Audit": {
        name: "Software Audit",
        icon: <Optimization1 />,
        subservices: [
          {
            name: "Deployment process audit",
            icon: <Service5 />,
            description: (
              <span>
                We’ll evaluate the stability of each aspect of your process
                framework focusing on carbon footprint reduction:
                <ul>
                  <li>Development and release process</li>
                  <li>Project management process</li>
                  <li>QA and BA process</li>
                  <li>Organisational framework (RACI)</li>
                </ul>
              </span>
            ),
          },
          {
            name: "Software Audit",
            icon: <Service18 />,
            description: (
              <span>
                Our team of experts run detailed diagnostics on the strength of
                your software solution, including:
                <ul>
                  <li>Architecture</li>
                  <li>Database & data model</li>
                  <li>Code quality</li>
                  <li>Integration layer</li>
                  <li>Security</li>
                  <li>UI/UX</li>
                  <li>Documentation quality</li>
                  <li>BA attributes</li>
                  <li>Testing artefacts</li>
                  <li>Sustainability</li>
                </ul>
              </span>
            ),
          },
          {
            name: "Post-Audit",
            icon: <Service19 />,
            description:
              "Once we’ve completed your evaluation, we’ll provide you with a summary report detailing any discrepancies – along with their severity, risks and recommendations. We’re also able to work with you to create a detailed action plan of issue fixes. Regularly assess and improve your product's environmental performance by gathering feedback from users, monitoring emissions data, and implementing updates and enhancements.",
          },
        ],
      },
      "Technical Support": {
        name: "Technical Support",
        icon: <Optimization2 />,
        subservices: [
          {
            name: "L2 Technical Support",
            icon: <Service20 />,
            description: (
              <span>
                We’ll help you quickly get back on your feet following a systems
                failure, without making any changes to your software code. Our
                L2 service covers:
                <ul>
                  <li>Applications</li>
                  <li>Databases</li>
                  <li>Integration points</li>
                  <li>Related environment components</li>
                  <li>Virtual cloud infrastructure</li>
                </ul>
              </span>
            ),
          },
          {
            name: "L3 Technical Support",
            icon: <Service21 />,
            description: (
              <span>
                We’ll appoint a team of experts to help you solve advanced
                software issues and make improvements, by changing your program
                code. Our L3 service covers:
                <ul>
                  <li>Bug fixes</li>
                  <li>Security and performance issues</li>
                  <li>System compliance checks</li>
                  <li>Updates to integration interfaces</li>
                </ul>
              </span>
            ),
          },
        ],
      },
      "Quality Assurance": {
        name: "Quality Assurance",
        icon: <Optimization3 />,
        subservices: [
          {
            name: "Software Testing and QA Services",
            icon: <Service22 />,
            description:
              "ELEKS’ custom-built quality assurance and testing services help you deliver a robust and UX-optimised product to market, up to 80% faster and 20% cheaper and also more eco-friendly.",
          },
        ],
      },
    },
  },
};
